"use client";
import { LoadingCircle } from "@/components/primitives/loading";
import { useQueryUpdater } from "@/utils/hooks/navigation";

import { useRouter } from "next/navigation";

export default function AuthTest() {
  const router = useRouter();
  const { createQueryParamString } = useQueryUpdater();
  const string = createQueryParamString("auth", "login").replace("/login", "/");

  router.push(string);

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <LoadingCircle />
    </div>
  );
}
